import axios from "axios";
import { useEffect, useState } from "react";
export default function Memberships() {
  const [memberships, setMemberships] = useState([]);
  const [isMonthly, setMonthly] = useState(true);

  useEffect(() => {
    axios
      .get("https://lode.gg/api/landing/memberships")
      .then((res) => {
        setMemberships(res?.data ?? []);
      })
      .catch(() => null);
  }, []);

  const togglePlan = () => {
    setMonthly(!isMonthly);
  };

  const [isOpen, setIsOpen] = useState(false);

  const openCheckout = () => {
    // let itemsList = [
    //   {
    //     priceId: "pri_01j7kwvxmzxs3z56xkvkjqz7tt",
    //     quantity: 1,
    //   },
    // ];

    // window.Paddle.Checkout.open({
    //   items: itemsList,
    // });

    window.openCheckout();
  };

  return (
    <div>
      <button onClick={openCheckout} className="paddle_button">
        Sign up now
      </button>
      <div className="from-[#000000] to-[#232323] bg-gradient-to-t object-cover fixed inset-0 w-full h-screen filter z-[-2]"></div>
      {/* <div className="fixed object-cover w-full h-screen inset-0 bg-black opacity-60 z-[-1]"></div> */}
      <div className="flex items-center justify-center flex-col my-10">
        <h1 className="font-heavitas text-4xl md:text-6xl text-center">
          Choose a Plan
        </h1>
        <p className="text-md md:text-xl max-w-md md:max-w-3xl text-center text-gray-300">
          Subscribe to one of three tiers to unlock exclusive perks, support
          Lodestone's development, and earn a unique profile badge! Cancel
          anytime.
        </p>
        <div className="flex items-center justify-center mt-4">
          <div className="relative w-40 h-12">
            <div
              className={`absolute top-0 left-0 w-20 h-full bg-orange-500 rounded-2xl transition-all duration-300 transform ${
                !isMonthly ? "translate-x-full bg-red-500" : ""
              }`}
            />
            <button
              onClick={togglePlan}
              className={`absolute left-0 w-1/2 h-full text-center font-semibold z-5 ${
                !isMonthly ? "text-gray-400" : "text-white"
              }`}
            >
              Monthly
            </button>
            <button
              onClick={togglePlan}
              className={`absolute right-0 w-1/2 h-full text-center font-semibold z-5 ${
                !isMonthly ? "text-white" : "text-gray-400"
              }`}
            >
              Annual
            </button>
          </div>
        </div>
        <p className="text-gray-300 mt-3">Save 16% with annual billing!</p>
        <div className="grid-cols-1 md:grid-cols-3 grid w-3/4">
          {memberships.map((membership) => {
            return (
              <div className="my-16 items-center justify-center flex flex-col">
                <img
                  src={membership.icon_url}
                  width={125}
                  className="mb-5 animate-bounce"
                  alt="tier"
                />
                <h1 className="font-heavitas text-3xl">{membership.name}</h1>
                <h2 className="text-3xl mb-5">
                  $
                  {isMonthly
                    ? membership.prices.monthly
                    : membership.prices.annual}
                  <span className="text-sm">
                    /{isMonthly ? "month" : "year"}
                  </span>
                </h2>
                <ul>
                  {membership.perks.map((perk) => {
                    return <li>- {perk}</li>;
                  })}
                </ul>
                <a
                  href={membership.url}
                  className={`rounded-2xl bg-orange-400 px-5 py-2 text-black mt-5 font-extrabold`}
                >
                  Subscribe {isMonthly ? "Monthly" : "Annually"}
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
