export function formatVersionRange(versions) {
    // Extract major.minor part from each version and get unique ones
    const uniqueVersions = [...new Set(versions.map(v => v.split('.').slice(0, 2).join('.')))];

    // Format the range as "x.x.x - x.x.x"
    return `${uniqueVersions[0]}.x - ${uniqueVersions[uniqueVersions.length - 1]}.x`;
}

export function titleCase(str) {
    return str.replace(/-/gi, " ").replace(/_/gi, " ").split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
}

export function timeAgo(date1, date2) {
    const intervals = [
        { label: 'year', ms: 365 * 24 * 60 * 60 * 1000 },
        { label: 'month', ms: 30 * 24 * 60 * 60 * 1000 },
        { label: 'day', ms: 24 * 60 * 60 * 1000 },
        { label: 'hour', ms: 60 * 60 * 1000 },
        { label: 'minute', ms: 60 * 1000 },
        { label: 'second', ms: 1000 }
    ];

    const elapsed = date2 - date1;

    for (let i = 0; i < intervals.length; i++) {
        const interval = intervals[i];
        const count = Math.floor(elapsed / interval.ms);
        if (count >= 1) {
            return count + ' ' + interval.label + (count > 1 ? 's' : '') + ' ago';
        }
    }

    return 'just now';
}

export function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]]; // Swap elements
    }
    return array;
}

export function formatNumber(num) {
    let formattedNum;

    if (num >= 1_000_000_000) {
        formattedNum = (num / 1_000_000_000).toFixed(2) + 'B'; // Billions
    } else if (num >= 1_000_000) {
        formattedNum = (num / 1_000_000).toFixed(2) + 'M'; // Millions
    } else if (num >= 1_000) {
        formattedNum = (num / 1_000).toFixed(2) + 'K'; // Thousands
    } else {
        return num.toString(); // Less than a thousand, no suffix
    }

    // Remove unnecessary decimals (e.g., .00 or .0)
    return formattedNum.replace(/\.00/, "").replace(/\.0/, "");
}