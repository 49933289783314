import axios from "axios";
import { useEffect, useState } from "react";
import { BiSearch } from "react-icons/bi";
import { formatVersionRange, titleCase } from "../util/util";
import { SiSpigotmc } from "react-icons/si";
import { FaRegPaperPlane } from "react-icons/fa";
import { LuScroll } from "react-icons/lu";
import { GrBucket } from "react-icons/gr";
import { TbCube } from "react-icons/tb";

const MODRINTH_URL = "https://modrinth.com/plugin";
const MODRINTH_API = "https://api.modrinth.com/v2/project";

export default function Plugins() {
  const [search, setSearch] = useState("");
  const [pluginStatistics, setPluginStatistics] = useState([]);
  const [plugins, setPlugins] = useState([]);

  useEffect(() => {
    axios.get("https://lode.gg/api/landing/plugins").then((res) => {
      setPlugins(res?.data ?? []);
    }).catch(() => null);
  }, [])

  useEffect(() => {
    const fetchPluginData = async () => {
      const pluginDataPromises = plugins.map(async (plugin) => {
        try {
          if (plugin.paid) return plugin;

          const pluginRes = await axios.get(`${MODRINTH_API}/${plugin.id}`);
          const { description, downloads, icon_url, title, wiki_url, categories, loaders } =
            pluginRes.data;

          const versionRes = await axios.get(
            `${MODRINTH_API}/${plugin.id}/version`
          );
          const versions = versionRes.data[0]?.game_versions || [];
          const version = versionRes.data[0]?.version_number || "Unknown";

          return {
            categories,
            loaders,
            versions,
            description,
            downloads,
            icon_url,
            title,
            version,
            id: plugin.id,
            wiki_url,
          };
        } catch (error) {
          console.error(`Error fetching data for plugin ${plugin.id}:`, error);
          return null;
        }
      });

      const pluginData = await Promise.all(pluginDataPromises);
      setPluginStatistics((prev) => [...prev, ...pluginData.filter(Boolean)]);
    };

    if (plugins.length > 0) fetchPluginData();
  }, [plugins]);

  return (
    <div className="flex flex-row items-center justify-center">
      <div className="from-[#000000] to-[#232323] bg-gradient-to-t object-cover fixed inset-0 w-full h-screen filter z-[-2]"></div>
      {/* Sidebar */}
      {/* <div className="bg-[#292929] rounded-xl px-5 py-5 w-[17rem]">
        <div className="text-gray-300 flex flex-row items-center justify-center w-max bg-[#3c3c3c]">
          <BiSearch size={25} />
          <input 
            className="bg-transparent focus:outline-none w-max"
            placeholder="Search"
          ></input>
        </div>
      </div> */}
      {/* Content */}
      <div className="ml-5 flex flex-col items-center justify-center mt-10">
        <h1 className="font-heavitas text-4xl md:text-6xl text-center">
          Plugins
        </h1>
        <p className="text-md md:text-xl max-w-md md:max-w-3xl text-center text-gray-300 mb-10">
          View our catalogue of plugins that can help you out with your events or videos!
        </p>
        {/* Search Box */}
        <div className="bg-[#292929] rounded-xl px-5 py-5 flex flex-row w-full md:w-[50%] items-center justify-center">
          <div className="text-gray-300 flex flex-row items-center justify-start w-full bg-[#3c3c3c] px-5 py-2 rounded-xl">
            <BiSearch size={25} />
            <input
              className="bg-transparent ml-3 focus:outline-none w-full"
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search"
              value={search}
            />
          </div>
        </div>
        {/* Grid */}
        <div className="my-2 w-[24rem] md:w-[52rem] lg:w-[80rem] 3xl:w-[124rem] 3xl:grid-cols-5 lg:grid-cols-3 md:grid-cols-2 grid grid-cols-1">
          {pluginStatistics.length === 0 && <div></div>}
          {pluginStatistics
            .filter(
              (p) =>
                search.length === 0 ||
                p.id.toLowerCase().includes(search.toLowerCase()) ||
                p.title.toLowerCase().includes(search.toLowerCase()) ||
                p.description.toLowerCase().includes(search.toLowerCase()) ||
                (p.categories ?? []).find(c => c.toLowerCase().includes(search.toLowerCase())) ||
                (p.loaders ?? []).find(l => l.toLowerCase().includes(search.toLowerCase()))
            )
            .map((plugin) => {
              return (
                <div
                  key={plugin.id}
                  className="rounded-xl bg-[#292929] px-5 py-5 mx-1 my-1"
                >
                  <a
                    href={`${MODRINTH_URL}/${plugin.id}`}
                    className="flex flex-row items-center"
                  >
                    <img
                      alt="logo"
                      src={plugin.icon_url}
                      width={85}
                      className="rounded-xl bg-[#343434]"
                    />
                    <div className="ml-4">
                      <h1 className="text-2xl">{plugin.title}</h1>
                      <p className="text-gray-300 text-md">{plugin.version}</p>
                      <p className="text-gray-300 text-md">
                        {formatVersionRange(plugin.versions)}
                      </p>
                    </div>
                  </a>
                  <div className="items-center justify-start text-gray-300 flex flex-row mt-3">
                    {(plugin.categories ?? []).map(category => {
                      return <p className="bg-[#434343] px-3 py-1 rounded-2xl mr-1 text-[12px]">{titleCase(category)}</p>
                    })}
                  </div>
                  <div className="items-center justify-start text-gray-300 flex flex-row mt-3">
                    {(plugin.loaders ?? []).map(loader => {
                      switch (loader.toUpperCase()) {
                        case "PAPER":
                          return <div className="items-center justify-center flex flex-row text-[#eaa] bg-[#434343] px-3 py-1 rounded-2xl mr-1 text-[12px]">
                            <FaRegPaperPlane size={15} className="mr-2" />
                            <p>{titleCase(loader)}</p>
                          </div>
                        case "BUKKIT":
                          return <div className="items-center justify-center flex flex-row text-[#f6af7b] bg-[#434343] px-3 py-1 rounded-2xl mr-1 text-[12px]">
                            <GrBucket size={15} className="mr-2" />
                            <p>{titleCase(loader)}</p>
                          </div>
                        case "FABRIC":
                          return <div className="items-center justify-center flex flex-row text-[#dbb69b] bg-[#434343] px-3 py-1 rounded-2xl mr-1 text-[12px]">
                            <LuScroll size={15} className="mr-2" />
                            <p>{titleCase(loader)}</p>
                          </div>
                        case "SPIGOT":
                          return <div className="items-center justify-center flex flex-row text-[#f1cc84] bg-[#434343] px-3 py-1 rounded-2xl mr-1 text-[12px]">
                            <SiSpigotmc size={15} className="mr-2" />
                            <p>{titleCase(loader)}</p>
                          </div>
                        case "PURPUR":
                          return <div className="items-center justify-center flex flex-row text-[#c3abf7] bg-[#434343] px-3 py-1 rounded-2xl mr-1 text-[12px]">
                            <TbCube size={15} className="mr-2" />
                            <p>{titleCase(loader)}</p>
                          </div>
                      }
                    })}
                  </div>
                  <div className="mt-2">
                    <p className="text-gray-300 h-28">{plugin.description}</p>
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href={!plugin.paid ? `${MODRINTH_URL}/${plugin.id}` : `https://lode.gg/plugins/${plugin.id}/checkout`}
                      className={`font-extrabold text-black ${plugin.paid ? "bg-red-400" : "bg-green-400"} px-3 py-2 rounded-xl flex items-center justify-center mt-4`}
                    >
                      {plugin.paid ? `Purchase for $${plugin.price}` : "View on Modrinth"}
                    </a>
                    {plugin.wiki_url && (
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href={plugin.wiki_url}
                        className="font-extrabold text-black bg-orange-400 px-3 py-2 rounded-xl flex items-center justify-center mt-2"
                      >
                        Read Documentation
                      </a>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}
