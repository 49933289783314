import Logo from "../assets/logo.png";
import Video from "../assets/HeroVideo.mp4";
import { RiServerLine, RiShoppingCart2Line } from "react-icons/ri";
import { formatNumber, shuffleArray } from "../util/util";
import { LiaWpforms } from "react-icons/lia";
import { BsDiscord } from "react-icons/bs";
import { useContext, useEffect, useState } from "react";
import { PageContext } from "../page-context";
import axios from "axios";

export default function Landing() {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const words = [
    "better",
    "easier",
    "efficient",
    "faster",
    "smoother",
    "optimized",
  ];
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [currentText, setCurrentText] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [speed, setSpeed] = useState(150); // Typing speed
  const { screenSize } = useContext(PageContext);

  const [views] = useState(500000000);
  const [creators, setCreators] = useState([]);

  useEffect(() => {
    axios.get("https://lode.gg/api/landing/creators").then((res) => {
      setCreators(res?.data ?? []);
    }).catch((e) => console.log(e));
  }, []);

  useEffect(() => {
    if (creators.length === 0) return;
    setCreators(shuffleArray(creators));
  }, [creators]);

  useEffect(() => {
    let typingTimeout;

    if (!isDeleting && currentText === words[currentWordIndex]) {
      // Pause before starting to delete
      typingTimeout = setTimeout(() => setIsDeleting(true), 1000);
    } else if (isDeleting && currentText === "") {
      // Move to the next word and reset typing
      setIsDeleting(false);
      setCurrentWordIndex((prev) => (prev + 1) % words.length);
      setSpeed(150); // Reset typing speed
    } else {
      // Determine if we should delete or type
      typingTimeout = setTimeout(() => {
        const updatedText = isDeleting
          ? words[currentWordIndex].substring(0, currentText.length - 1)
          : words[currentWordIndex].substring(0, currentText.length + 1);

        setCurrentText(updatedText);

        // Speed up deleting
        setSpeed(isDeleting ? 75 : 150);
      }, speed);
    }

    return () => clearTimeout(typingTimeout);
  }, [currentText, isDeleting, words, currentWordIndex, speed]);

  return (
    <div className="flex flex-col items-center justify-center h-full">
      <div
        className={`static w-full h-[85vh] overflow-hidden flex flex-col items-center justify-center`}
      >
        <video
          loop
          playsInline
          muted
          autoPlay
          className="object-cover fixed inset-0 w-full h-full filter blur-md z-[-1] my-25"
          src={Video}
        ></video>
        <img
          src={Logo}
          width={screenSize < 700 ? 150 : 250}
          className="animate-bounce"
          alt="logo of lodestone"
        />
        <h1 className="text-5xl md:text-8xl font-heavitas uppercase text-center">
          Lodestone
        </h1>
        <h3 className="mt-4 text-2xl md:text-5xl text-center">
          Production just got{" "}
          <span className="font-heavitas-semibold underline text-orange-400">
            {currentText}
          </span>
        </h3>
        {screenSize < 700 ? (
          <>
            <div className="flex flex-row items-center justify-center my-7">
              <a
                href="/plugins"
                className="cursor-pointer mx-3 px-5 py-3 transition-all hover:text-orange-200 text-orange-400 outline-orange-400 hover:outline-orange-200 outline rounded-2xl flex flex-row items-center justify-center"
              >
                <RiShoppingCart2Line size={20} />
                <p className="ml-2 text-xs">Browse our Plugins</p>
              </a>
              <a
                href="/events"
                className="cursor-pointer mx-3 px-5 py-3 outline-gray-300 hover:outline-white outline text-gray-300 hover:text-white transition-all rounded-2xl flex flex-row items-center justify-center"
              >
                <LiaWpforms size={20} />
                <p className="ml-2 text-xs">Apply for Events</p>
              </a>
            </div>
            <a
              href="/discord"
              className="cursor-pointer mx-3 px-5 py-3 outline-yellow-300 hover:outline-yellow-200 outline text-yellow-300 hover:text-yellow-200 transition-all rounded-2xl flex flex-row items-center justify-center"
            >
              <BsDiscord size={20} />
              <p className="ml-2 text-xs">Join our Discord</p>
            </a>
          </>
        ) : (
          <div className="flex flex-row items-center justify-center my-7">
            <a
              href="/plugins"
              className="cursor-pointer mx-3 px-5 py-3 transition-all hover:text-orange-200 text-orange-400 outline-orange-400 hover:outline-orange-200 outline rounded-2xl flex flex-row items-center justify-center"
            >
              <RiServerLine size={25} className="mr-1" />
              <p className="ml-2 inter">Browse our Plugins</p>
            </a>
            <a
              href="/events"
              className="cursor-pointer mx-3 px-5 py-3 outline-gray-300 hover:outline-white outline text-gray-300 hover:text-white transition-all rounded-2xl flex flex-row items-center justify-center"
            >
              <LiaWpforms size={25} />
              <p className="ml-2 inter">Apply for Events</p>
            </a>
            <a
              href="/discord"
              className="cursor-pointer mx-3 px-5 py-3 outline-yellow-300 hover:outline-yellow-200 outline text-yellow-300 hover:text-yellow-200 transition-all rounded-2xl flex flex-row items-center justify-center"
            >
              <BsDiscord size={25} />
              <p className="ml-2 inter">Join our Discord</p>
            </a>
          </div>
        )}
      </div>
      <div className="my-10 text-center">
        <h1 className="text-4xl font-heavitas uppercase">
          {screenSize < 700 ? "Creators" : "Used by these Creators"}
        </h1>
        <p className="max-w-sm md:max-w-3xl text-center text-xl mx-auto">
          Our content has been used by various Creators, racking up to at least{" "}
          <span className="underline">{formatNumber(views)}</span> views!
        </p>

        <div className="relative mt-8 overflow-hidden right-5">
          <div className={`flex flex-nowrap gap-4 ${screenSize < 700 ? "animate-infinite-scroll-mobile" : "animate-infinite-scroll"}`}>
            {[...creators, ...creators].map((creator, index) => (
              <div
                key={index}
                className="w-56 h-[10rem] rounded-xl outline-1 outline-white bg-[rgb(53,52,52)] relative p-4 flex flex-col justify-between"
              >
                <div className="flex flex-row items-center justify-center">
                  {/* Creator Icon */}
                  <img
                    alt={`${creator.name} icon`}
                    src={creator.icon_url}
                    width={80}
                    height={80}
                    className="ml-1 outline-1 outline-white rounded-full z-5"
                  />

                  {/* Creator Info */}
                  <div className="ml-2 flex flex-col items-center justify-center">
                    <h3 className="text-md font-bold">
                      {creator.name.length > 15
                        ? `${creator.name.substring(0, 15)}...`
                        : creator.name}
                    </h3>
                    <p className="text-xs text-gray-300">
                      {formatNumber(creator.amount)}{" "}
                      {creator.type === "SUBSCRIBE" ? "Subscribers" : "Members"}
                    </p>
                  </div>
                </div>

                {/* Subscribe Button */}
                <a
                  href={creator.link}
                  className={`${creator.type === "SUBSCRIBE"
                    ? "bg-red-600 hover:bg-red-500"
                    : "bg-[#5350d5] hover:bg-[#6a68ef]"
                    } transition-colors text-white text-md w-full py-2 rounded-xl`}
                >
                  {creator.type === "SUBSCRIBE" ? "Subscribe" : "Join Discord"}
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>

    </div>
  );
}
