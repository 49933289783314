import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

import "./css/index.css";
import { PageContext } from "./page-context";
import { useEffect, useState } from "react";
import Landing from "./pages/Landing";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Plugins from "./pages/Plugins";
import Memberships from "./pages/shop/Memberships";
import Terms from "./pages/legal/Terms";
import Privacy from "./pages/legal/Privacy";
import Refund from "./pages/legal/Refund";
import Copyright from "./pages/legal/Copyright";
import Rules from "./pages/legal/Rules";
import Vulnerabilities from "./pages/legal/Vulernabilities";

function App() {
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <PageContext.Provider value={{ screenSize }}>
      <Navbar />
      <Router>
        <Switch>
          <Route path={["/"]} exact component={Landing} />
          <Route path={["/plugins", "/plugin"]} exact component={Plugins} />
          <Route path={["/upgrade", "/memberships"]} exact component={Memberships} />
          <Route path={["/legal/terms", "/legal/tos", "/legal/terms-of-service"]} exact component={Terms}/>
          <Route path={["/legal/privacy", "/legal/privacy-policy"]} exact component={Privacy}/>
          <Route path={["/legal/refund", "/legal/refund-policy"]} exact component={Refund}/>
          <Route path={["/legal/copyright"]} exact component={Copyright}/>
          <Route path={["/legal/vulnerabilities"]} exact component={Vulnerabilities}/>
          <Route path={["/legal/rules", "/legal/regulations", "/legal/rules-regulation"]} exact component={Rules}/>
          <Redirect to="/"></Redirect>
        </Switch>
      </Router>
      <Footer />
    </PageContext.Provider>
  );
}

export default App;
